/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
			},
			finalize: function() {
			}
		},
		'que_ofrecemos':{
			init: function(){
				$('.read-more' ).click(function(e){

					$(this ).parents('article' ).next().slideDown('fast' );

					e.stopPropagation();
					return false;
				} );
			}
		},
		// Home page
		'home': {
			init: function() {

				$('.colorbox' ).colorbox({iframe:true, innerWidth:640, innerHeight:390});

				function parallax(obj, multiplier) {
					var scrollPosition = $(window).scrollTop();
					obj.css('top', (0 - (scrollPosition * multiplier)) + 'px');
				}

				scrollIntervalID = setInterval(function(){
					parallax($('.bg-services img'),0.2);
					parallax($('.bg-bim img'),0.16);
					parallax($('.bg-inspeccion img'),0.14);
					parallax($('.bg-licitacion img'),0.12);
					parallax($('.bg-gerenciamiento img'),0.1);
					parallax($('.parallax-obj.c'),0.3);
					parallax($('.parallax-obj.o'),0.75);
					parallax($('.parallax-obj.x'),0.25);
					parallax($('.parallax-obj.weird-c'),0.2);
					parallax($('.parallax-obj.a'),0.52);
					parallax($('.parallax-obj.aaa'),1);
					parallax($('.parallax-obj.azules') ,0.25);
					parallax($('.parallax-obj.blancos') ,-0.09);
					parallax($('.parallax-obj.box1') ,1);
					parallax($('.parallax-obj.box2') ,1.5);
				}, 10);

				$('a[href*=#]:not([href=#])').click(function() {
					if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
						var target = $(this.hash);
						target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
						if (target.length) {
							$('html,body').animate({
								scrollTop: target.offset().top-240 //240 es la altura del header
							}, 1000);
							return false;
						}
					}
				});

			},
			finalize: function() {
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';
			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');
			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});
			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.